<template>
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.20187 20.621L1.20554 20.6246C1.30296 20.7228 1.4188 20.8007 1.54642 20.8539C1.67404 20.9071 1.81092 20.9345 1.94919 20.9346C2.06553 20.9346 2.18108 20.9154 2.2912 20.8779L8.28724 18.8438L19.7932 7.33785C20.4966 6.63439 20.8918 5.68033 20.8917 4.68554C20.8917 3.69075 20.4965 2.73672 19.793 2.03333C19.0896 1.32994 18.1355 0.934801 17.1407 0.934845C16.1459 0.934889 15.1919 1.33011 14.4885 2.03356L2.98249 13.5396L0.948655 19.5354C0.884589 19.7219 0.874363 19.9226 0.919149 20.1146C0.963935 20.3066 1.06192 20.4821 1.20187 20.621ZM15.444 2.98896C15.8946 2.54178 16.504 2.29138 17.1389 2.2926C17.7737 2.29382 18.3822 2.54656 18.8311 2.99547C19.28 3.44437 19.5327 4.05287 19.5339 4.68772C19.5351 5.32256 19.2847 5.93201 18.8375 6.38262L17.3245 7.89567L13.9308 4.50201L15.444 2.98896ZM4.16096 14.2719L12.9754 5.45741L16.3691 8.85107L7.55457 17.6655L2.41872 19.4078L4.16096 14.2719Z"
      fill="black"
    />
  </svg>
</template>

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex hover:bg-opacity-90 items-center justify-between group bg-white shadow-md focus:border-blue-400 active:border-2 rounded-md p-3" }
const _hoisted_2 = { class: "w-full" }
const _hoisted_3 = { class: "flex justify-between" }
const _hoisted_4 = { class: "font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditTask = _resolveComponent("EditTask")!
  const _component_PenIcon = _resolveComponent("PenIcon")!
  const _component_TaskDescription = _resolveComponent("TaskDescription")!
  const _component_TaskDueDate = _resolveComponent("TaskDueDate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isEditting(_ctx.$props.taskId, _ctx.$props.statusId))
        ? (_openBlock(), _createBlock(_component_EditTask, { key: 0 }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$props.title), 1),
              _createElementVNode("div", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (
              _ctx.editTask({
                taskId: _ctx.$props.taskId,
                statusId: _ctx.$props.statusId,
                title: _ctx.$props.title,
                description: _ctx.$props.description,
                date: _ctx.$props.dueDate,
              })
            ))
              }, [
                _createVNode(_component_PenIcon, { class: "md:hidden group-hover:block w-4 h-4 cursor-pointer" })
              ])
            ]),
            _createVNode(_component_TaskDescription, { class: "text-sm" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$props.description), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_TaskDueDate, { class: "border-t border-white border-opacity-25 mt-1.5 pt-1.5" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$props.formattedDate), 1)
              ]),
              _: 1
            })
          ], 64))
    ])
  ]))
}
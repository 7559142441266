<template>
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.8917 10.0653H11.7613V0.934845H10.0222V10.0653H0.891724V11.8044H10.0222V20.9348H11.7613V11.8044H20.8917V10.0653Z"
      fill="black"
    />
  </svg>
</template>

<template>
  <svg
    width="11"
    height="10"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.78833 1.26679H7.955V0.100128H7.28833V1.26679H3.28833V0.100128H2.62166V1.26679H0.78833C0.655769 1.26695 0.528682 1.31968 0.434947 1.41341C0.341212 1.50715 0.288484 1.63423 0.28833 1.76679V8.76679C0.288484 8.89936 0.341212 9.02644 0.434947 9.12018C0.528682 9.21391 0.655769 9.26664 0.78833 9.26679H9.78833C9.92089 9.26664 10.048 9.21391 10.1417 9.12018C10.2354 9.02644 10.2882 8.89936 10.2883 8.76679V1.76679C10.2882 1.63423 10.2354 1.50715 10.1417 1.41341C10.048 1.31968 9.92089 1.26695 9.78833 1.26679ZM9.62166 8.60013H0.954997V1.93346H2.62166V2.76679H3.28833V1.93346H7.28833V2.76679H7.955V1.93346H9.62166V8.60013Z"
      fill="black"
    />
    <path d="M2.955 3.93347H2.28833V4.60014H2.955V3.93347Z" fill="black" />
    <path d="M4.78831 3.93347H4.12164V4.60014H4.78831V3.93347Z" fill="black" />
    <path d="M6.455 3.93347H5.78833V4.60014H6.455V3.93347Z" fill="black" />
    <path d="M8.28831 3.93347H7.62164V4.60014H8.28831V3.93347Z" fill="black" />
    <path d="M2.955 5.43347H2.28833V6.10014H2.955V5.43347Z" fill="black" />
    <path d="M4.78831 5.43347H4.12164V6.10014H4.78831V5.43347Z" fill="black" />
    <path d="M6.455 5.43347H5.78833V6.10014H6.455V5.43347Z" fill="black" />
    <path d="M8.28831 5.43347H7.62164V6.10014H8.28831V5.43347Z" fill="black" />
    <path d="M2.955 6.93347H2.28833V7.60014H2.955V6.93347Z" fill="black" />
    <path d="M4.78831 6.93347H4.12164V7.60014H4.78831V6.93347Z" fill="black" />
    <path d="M6.455 6.93347H5.78833V7.60014H6.455V6.93347Z" fill="black" />
    <path d="M8.28831 6.93347H7.62164V7.60014H8.28831V6.93347Z" fill="black" />
  </svg>
</template>

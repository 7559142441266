
import { defineComponent, onMounted } from "vue";
import PenIcon from "@/components/icons/PenIcon.vue";
import TaskDescription from "@/components/task/TaskDescription.vue";
import TaskDueDate from "@/components/task/TaskDueDate.vue";
import EditTask from "@/components/task/EditTask.vue";
import { useTaskStore } from "@/stores/tasks";

interface SelectedTask {
  taskId: number | null | undefined;
  statusId: number | null | undefined;
  title: string | null | undefined;
  description: string | null | undefined;
  date: string | null | undefined;
}

export default defineComponent({
  props: {
    title: {
      type: String,
      default: "Task 1",
    },
    description: {
      type: String,
      default: "Description of Task",
    },
    dueDate: {
      type: String,
      default: "",
    },
    formattedDate: {
      type: String,
      default: "",
    },
    statusId: {
      type: Number,
      default: 0,
    },
    taskId: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const taskStore = useTaskStore();
    const isEditting = (taskId: number, statusId: number) => {
      if (
        taskId === taskStore.selectedTask.taskId &&
        statusId === taskStore.selectedTask.statusId
      ) {
        return true;
      }
      return false;
    };
    const editTask = ({
      taskId,
      statusId,
      title,
      description,
      date,
    }: SelectedTask) => {
      taskStore.setSelectedTask({
        taskId,
        statusId,
        title,
        description,
        date,
      } as SelectedTask | any);
    };

    onMounted(() => {
      taskStore.setSelectedTask({
        taskId: null,
        statusId: null,
        title: null,
        description: null,
        date: null,
      } as SelectedTask | any);
    });

    return { editTask, isEditting };
  },

  components: {
    PenIcon,
    TaskDescription,
    TaskDueDate,
    EditTask,
  },
});
